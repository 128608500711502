import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import AnimationContainer from 'components/animation-container'
import Letras_unicas from 'components/letras-unicas'
import $ from 'jquery';
import AnimatedHeading from 'components/animated-heading'
import fondo from 'sections/images/fondos/f3.jpg'
import Idioma from 'sections/idiomas/contents.json'
import Cookies from 'universal-cookie';
import Swal from 'sweetalert2'
import LoopVideo from 'sections/hero/assets/vid_fon.mp4'
class Lista_blancas extends React.Component {
  
  constructor(props){
    const cookies = new Cookies();
    super(props)
    let title=""
    let a=cookies.get('idioma')
    let contenido2=""
    let paso1=""
    let paso2=""
    let paso3=""
    let paso4=""
    let paso5=""
    let recompenza=""
    if(a=="ingles"){
         contenido2=Idioma.ingles.lista_blanca.contenido2;
         paso1=Idioma.ingles.lista_blanca.paso1;
         paso2=Idioma.ingles.lista_blanca.paso2;
         paso3=Idioma.ingles.lista_blanca.paso3;
         paso4=Idioma.ingles.lista_blanca.paso4;
         paso5=Idioma.ingles.lista_blanca.paso5;
         recompenza=Idioma.ingles.lista_blanca.recompenza;
    }else{
         contenido2=Idioma.espanol.lista_blanca.contenido2;
         paso1=Idioma.espanol.lista_blanca.paso1;
         paso2=Idioma.espanol.lista_blanca.paso2;
         paso3=Idioma.espanol.lista_blanca.paso3;
         paso4=Idioma.espanol.lista_blanca.paso4;
         paso5=Idioma.espanol.lista_blanca.paso5;
         recompenza=Idioma.espanol.lista_blanca.recompenza;
    }

    this.state={
         contenido2,
        paso1,
        paso2,
        paso3,
        paso4,
        paso5,
        recompenza
    }
   
  }

    shouldComponentUpdate() {
        return false
    }
   

    render() {
        const Section = styled.section`
            position: relative;
            overflow: hidden;
            
            background-image: url(${LoopVideo});
            background-size: cover;
            background-repeat: no-repeat;
           
            .heading {
                width: 100%;
            }
          }
        `

        const ServiceContainer = styled.div`
          
            padding: 100px 0;
        `


        const ServiceElement = styled.div`
            margin-bottom: 20px;
            text-align: center;
            padding: 40px;
            border-radius: 20px;
            transition: .2s;
            &:hover {
                background-color: #000;
                box-shadow: 0 28px 60px rgb(4, 229, 229, .1);
                transform: translateY(-10px);
            }
        `
         const ServiceHeading = styled.h4`
            font-size: 20px;
            -webkit-text-stroke: 1px black;
            font-weight: 500;
            font-family: gameovercre;
            color: #00FFFF;
        `
        const ServiceSeparator = styled.div`
            height: 5px;
            width: 50px;
            background-color: #00FF00;
            margin-bottom: 10px;
            margin: auto;
         `
        const ServiceIcon = styled.div`
            margin-bottom: 20px;
            img {
                max-height: 70px;
            }
        `

        const ServiceList = styled.ul`
            padding: 0;
            margin: 11px 0 0 0;
        `

        const ServiceListElement = styled.li`
            list-style: none;
            -webkit-text-stroke: 1px black;
            color: #fff;
            font-weight: 300;
            font-size: 20px;
            margin: 5px 0px;
            &::before {
                display: inline-block;
                content: '';
                border-radius: 2.5px;
                height: 5px;
                width: 5px;
                margin-right: 10px;
                background-color:#04e5e5;
                position: relative;
                bottom: 2.5px;
            }
        `
      function aparecer(id){
        desaparecer()
            var ids="#"+id;
            $(ids).show()
          
        }

    function desaparecer(){
        for (let index = 1; index <= 6; index++) {
            var ids="#p"+index
            $(ids).hide()
        }
    }
    let uno=false;
    let dos=false;
    let tres=false;
    let cuatro=false;
    let cinco=false;
    function validar(paso){
       
            switch (paso) {
                case 1:
                    $("#uno").removeClass(" btn-primary").addClass(" btn-success");
                    uno=true;
                break;
                case 2:
                    $("#dos").removeClass(" btn-primary").addClass(" btn-success");
                    dos=true;
                break;
                case 3:
                    $("#tres").removeClass(" btn-primary").addClass(" btn-success");
                    tres=true;
                break;
                case 4:
                    $("#cuatro").removeClass(" btn-primary").addClass(" btn-success");
                    cuatro=true;
                break;
                case 5:
                    $("#cinco").removeClass(" btn-primary").addClass(" btn-success");
                    cinco=true;
                break;
              
            }
           if(uno
            && dos
            && tres
            && cuatro
            && cinco){
              Swal.fire(
                'Felicidades',
                'has obtenido un ticket ',
                'success'
              )
              $("#botones").hide();
              $("#billeteras").show();
           }
    }

    function guardar_billetera(){
      Swal.fire(
        'BUEN TRABAJO',
        'ESTAS MAS CERCA DEL METAVERSO',
        'success'
      )
     
    }
          
        

        return(
            <Section id="services">
                <ServiceContainer>
                    <Container>
                        <AnimatedHeading text="Objetivos" />
                        <div className='container'>
                        <div className='row justify-content-center'>
                            <div className='col-md-12 text-left ' id="botones">
                                <h4 className='text-center'>{this.state.contenido2}</h4>
                                <h4>{this.state.paso1}  <button type="button" class="btn btn-primary " onClick={()=>validar(1)} id="uno">click</button></h4> 
                                <h4>{this.state.paso2}  <button type="button" class="btn btn-primary "  onClick={()=>validar(2)}  id="dos">click</button></h4>
                                <h4>{this.state.paso3}  <button type="button" class="btn btn-primary "  onClick={()=>validar(3)}  id="tres">click</button></h4>
                                <h4>{this.state.paso4}  <button type="button" class="btn btn-primary "  onClick={()=>validar(4)}  id="cuatro">click</button></h4>
                                <h4>{this.state.paso5}  <button type="button" class="btn btn-primary " onClick={()=>validar(5)}  id="cinco">click</button></h4>
                                <button type="button" class="btn btn-primary text-center" style={{display:'none'}} id="recompenza">{this.state.recompenza}</button>
                            </div>
                            <div id='billeteras' style={{display:'none'}}>
                              <div class="form-group text-center">
                                Escribe tu billetera
                                      <input type="text" name="name" className='form-control' placeholder=''/>
                                      <br></br>
                                      <input type="submit" value="Verificar"  onClick={()=>guardar_billetera()}/>
                              </div>
                            </div>
                           
                        </div>
                    </div>
                    </Container>
                </ServiceContainer>
            </Section>
        )
    }

}

export default props => (
    <StaticQuery
      query={graphql`
      query {
        background: file(relativePath: {eq: "bg2.jpg"}) {
          childImageSharp {
            fluid(maxWidth: 4000, quality: 100) {
              src
            }
          }
        }
        web: file(relativePath: {eq: "icons/web.png"}) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
        mobile: file(relativePath: {eq: "icons/mobile.png"}) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
        seo: file(relativePath: {eq: "icons/seo2.png"}) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
        email: file(relativePath: {eq: "icons/email.png"}) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
        ui: file(relativePath: {eq: "icons/sketch.png"}) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
        network: file(relativePath: {eq: "icons/network.png"}) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
      }      
      `}
      render={({ 
        background,
        web,
        mobile,
        seo,
        email,
        ui,
        network}) => <Lista_blancas  
        background={background}
        web={web}
        mobile={mobile}
        seo={seo}
        email={email}
        ui={ui}
        network={network}
        {...props} />}
    />
  )