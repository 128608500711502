import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Col, Container } from 'react-bootstrap'
import styled from 'styled-components'
import TeamItem from 'sections/team/parts/TeamItem.js'
import AnimationContainer from 'components/animation-container'
import AnimatedHeading from 'components/animated-heading'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Particles from 'react-particles-js';
import fondo from 'sections/images/fondos/f1.jpg'
import Cookies from 'universal-cookie';
class Teamcrypto extends React.Component {
 
 
  constructor(props){
    const cookies = new Cookies();
    super(props)
    let title=""
    let a=cookies.get('idioma')
    if(a=="ingles"){
        title="i"
    }else{
        title="e"
    }

    this.state={
      titulo:title
     
    }
   
  }
    shouldComponentUpdate() {
      return false
    }
    
    render() {

        const Section = styled.section`
        background-image: url(${fondo});
        background-size: cover;
        background-repeat: no-repeat;
          padding: 100px 0;
          position: relative;
          .particles {
            position: absolute;
            width: 100%;
            height: 100%;}
        `

        const Overlay = styled.div`
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
         
        `
        const TeamContainer = styled.div`
            @media (max-width:767px) {
              padding: 0 50px;
            }
            .slick-dots {
              li button:before,.slick-dots li.slick-active button:before {
                color: #04e5e5;
              }
            }
        `

        const settings = {
          dots: true,
          swipe: true,
          infinite: true,
          speed: 500,
          slidesToShow: 4,
          slidesToScroll: 2,
          arrows: false,
          autoplay: true,
          autoplaySpeed: 10000,
          loop: true,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 4
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 3,
                initialSlide: 3
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
      }

        return (
            <Section id="team">
                
                  <Overlay />
                  <Particles
                    className="particles"
                    params={{
                        "particles": {
                            "number": {
                              "value": 100,
                              "density": {
                                "enable": true,
                                "value_area": 2000
                              }
                            },
                            "color": {
                              "value": ["#03afaf","#04e5e5"]
                            },
                            "shape": {
                              "type": "circle",
                              "stroke": {
                                "width": 0,
                                "color": "#fff"
                              }
                            },
                            "opacity": {
                              "value": 0.5,
                              "random": false,
                              "anim": {
                                "enable": true,
                                "speed": 0.5,
                                "opacity_min": 0.1,
                                "sync": false
                              }
                            },
                            "size": {
                              "value": 8.017060304327615,
                              "random": true,
                              "anim": {
                                "enable": true,
                                "speed": 12.181158184520175,
                                "size_min": 0.1,
                                "sync": true
                              }
                            },
                            "line_linked": {
                              "enable": true,
                              "distance": 150,
                              "color": "#555",
                              "opacity": 0.5,
                              "width": 1
                            },
                            "move": {
                              "enable": true,
                              "speed": 1,
                              "direction": "none",
                              "random": false,
                              "straight": false,
                              "out_mode": "bounce",
                              "bounce": false,
                              "attract": {
                                "enable": false,
                                "rotateX": 600,
                                "rotateY": 1200
                              }
                            }
                          },
                          "interactivity": {
                            "detect_on": "canvas",
                            "events": {
                              "onhover": {
                                "enable": false,
                                "mode": "repulse"
                              },
                              "onclick": {
                                "enable": false,
                                "mode": "push"
                              },
                              "resize": true
                            },
                            "modes": {
                              "grab": {
                                "distance": 400,
                                "line_linked": {
                                  "opacity": 1
                                }
                              },
                              "bubble": {
                                "distance": 400,
                                "size": 40,
                                "duration": 2,
                                "opacity": 8,
                                "speed": 3
                              },
                              "repulse": {
                                "distance": 200,
                                "duration": 0.4
                              },
                              "push": {
                                "particles_nb": 4
                              },
                              "remove": {
                                "particles_nb": 2
                              }
                            }
                          },
                          "retina_detect": true
                }}/>
                  <Col md={12}>
                    <Container>
                      <AnimatedHeading text="Our Team" />
                    </Container>
                  <TeamContainer>
                    <Container>
                        <AnimationContainer animation="fadeIn">
                          <Slider {...settings}>
                            {this.team()}
                          </Slider>
                        </AnimationContainer>
                    </Container>
                  </TeamContainer>
                </Col>
            </Section>
        )
    }

  team() {
   
      const { items } = this.props
      
      return items.map((value, index) => {
        let sdfa=this.state.titulo=="i"?value.content.frontmatter.profession1:value.content.frontmatter.profession;
        return (
            <TeamItem 
                key={index}
                index={index} 
                image={value.content.frontmatter.image.childImageSharp.fluid.src} 
                name={value.content.frontmatter.name} 
                profession={sdfa}
                facebook={value.content.frontmatter.facebook}
                twitter={value.content.frontmatter.twitter}
                linkedin={value.content.frontmatter.linkedin}
                github={value.content.frontmatter.github}
                type="slider"
            />
        )
      })
    }
}

export default props => (
  <StaticQuery
      query={graphql`
          query {
            background: file(relativePath: {eq: "bg3.jpg"}) {
              childImageSharp {
                fluid(maxWidth: 4000, quality: 100) {
                  src
                }
              }
            }
            items: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(team)/"}}, sort: {fields: [frontmatter___id], order: ASC}, limit: 14) {
              edges {
                content: node {
                  frontmatter {
                    id
                    name
                    profession
                    facebook
                    twitter
                    linkedin
                    github
                    profession1
                    image {
                      childImageSharp {
                        fluid(maxWidth: 800) {
                          src
                        }
                      }
                    }
                  }
                }
              }
            }
          }                    
          `}
      render={({ items, background }) => <Teamcrypto items={items.edges} background={background} {...props} />}
  />
)